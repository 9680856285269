<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      tile
      flat
    >
      <v-toolbar
        dark
        flat
        color="white"
      ></v-toolbar>
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <h1 class="primary--text hidden-xs-only" style="font-family: Roboto;font-size: 2.2rem;font-weight: 900;">주문/결제</h1>
          <h3 class="primary--text d-flex d-sm-none" style="font-family: Roboto;font-size: 1.5rem;font-weight: 900;">주문/결제</h3>
          <div class="mb-6">
            <span class="text-subtitle-2 primary--text" style="font-size: 1.5rem;font-weight: 600;">✻ Lawork 이용을 위한 결제페이지 입니다.</span>
          </div>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <span class="text-h6 primary--text font-weight-bold">LAWORK 정보</span>
          <v-progress-linear
            :indeterminate="isProgress"
            rounded
            color="primary"
            :height="isProgress ? 6 : 4"
            :value="progressValue"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-card
            flat
            tile
            class="pa-3"
          >
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">사무실명</h4>
              </v-col>
              <v-col>
                {{ this.form.officeName }}
              </v-col>
              <!-- <v-col cols="3" class="text-center"></v-col> -->
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">결제자</h4>
              </v-col>
              <v-col>
                {{ this.form.name }}
              </v-col>
              <!-- <v-col cols="3" class="text-center"></v-col> -->
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">주소</h4>
              </v-col>
              <v-col>
                {{ this.form.post ? `(${this.form.post}) ` : '' }}{{ this.form.addr1 }} {{ this.form.addr2 }}
              </v-col>
              <!-- <v-col cols="3" class="text-center"></v-col> -->
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">전화번호</h4>
              </v-col>
              <v-col>
                {{ this.form.tel }}
              </v-col>
              <!-- <v-col cols="3" class="text-center"></v-col> -->
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">팩스번호</h4>
              </v-col>
              <v-col>
                {{ this.form.fax }}
              </v-col>
              <!-- <v-col cols="3" class="text-center"></v-col> -->
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
        class="mt-3 mb-12"
      >
        <v-col cols="12" md="6" sm="8">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <span class="text-h6 primary--text font-weight-bold">상품선택</span>
          <v-progress-linear
            :indeterminate="isProgress"
            rounded
            reverse
            color="primary"
            :height="isProgress ? 6 : 4"
            :value="progressValue"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <!-- 상품선택: 가격 -->
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-card
            tile
            flat
            class="pa-3"
          >
            <v-radio-group
              v-model="rgCount"
              @change="changeCount"
            >
              <template v-for="item in priceTable">
                <v-row
                  :key="item.code"
                  no-gutters
                  align="center"
                  justify="center"
                  class="py-2"
                >
                  <v-col cols="4">
                    <v-radio
                      :label="item.name"
                      :value="item.code"
                      :disabled="item.disable"
                      class="subtitle-2 font-weight-bold ml-5"
                    ></v-radio>
                  </v-col>
                  <v-col>
                    <template v-if="item.code === 'W0' && item.caption !== ''">
                      {{ item.caption }}
                    </template>
                    <template v-else>
                      월 {{ numberFormat(item.price) }} 원
                    </template>
                  </v-col>
                </v-row>
              </template>
            </v-radio-group>
          </v-card>
        </v-col>
      </v-row>
      <!--/ 상품선택: 가격 -->

      <v-row
        no-gutters
        align="center"
        justify="center"
        class="my-0"
      >
        <v-col cols="12" md="6" sm="8">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <!-- 상품선택: 기간 -->
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-card
            tile
            flat
            class="pa-3"
          >
            <v-radio-group
              v-model="rgTerm"
              @change="changeTerm"
            >
              <template v-for="item in termTable">
                <v-row
                  :key="item.term"
                  no-gutters
                  align="center"
                  justify="center"
                  class="py-2"
                >
                  <v-col cols="4">
                    <v-radio
                      :label="`${item.term}개월`"
                      :value="item.code"
                      :disabled="item.disable"
                      class="subtitle-2 font-weight-bold ml-5"
                    ></v-radio>
                  </v-col>
                  <v-col>
                    <template v-if="item.dc === 0">
                      &nbsp;
                    </template>
                    <template v-else>
                      -{{ item.dc }}% 할인
                    </template>
                  </v-col>
                </v-row>
              </template>
            </v-radio-group>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
        class="my-0"
      >
        <v-col cols="12" md="6" sm="8">
          <v-divider class="mb-2"></v-divider>
        </v-col>
      </v-row>

      <!-- 결제금액 계산 section -->
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-card
            tile
            flat
            class="pa-0 ma-0"
          >
            <v-alert
              text
              color="info"
            >
              <h3 class="text-h5 font-weight-medium">
                결제금액
              </h3>
              <!-- <div>이용자수와 개월수에 할인율을 적용한 금액입니다.</div> -->
              <div class="text-body-2">
                <v-icon small color="blue darken-1">mdi-alert-circle-outline</v-icon>
                VAT 포함금액입니다.
              </div>

              <v-divider
                class="my-4 info"
                style="opacity: 0.22"
              ></v-divider>

              <v-row
                align="center"
                no-gutters
              >
                <v-col cols="4">
                  <h3 class="ml-4 font-weight-bold">
                    <v-icon small class="mr-2" color="blue darken-1">mdi-plus-box</v-icon>
                    {{ rgCountStr }}
                  </h3>
                  <h3 class="ml-4 font-weight-bold">
                    <v-icon small class="mr-2" color="blue darken-1">mdi-plus-box</v-icon>
                    {{ rgTermStr }}개월
                  </h3>
                  <!-- <h3 v-show="selGood.isDc" class="ml-4 font-weight-bold warning--text">
                    <v-icon small class="mr-2" color="warning">mdi-minus-box</v-icon>
                    -{{ selGood.dc_rate }}%
                  </h3> -->
                  <h3 v-show="selGood.isDc" class="ml-4 font-weight-bold warning--text">
                    <v-icon small class="mr-2" color="warning">mdi-minus-box</v-icon>
                    -{{ numberFormat(selGood.dc_price) }}원
                  </h3>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <h2 class="mr-4 font-weight-bold">
                    {{ numberFormat(totalPayStr) }}원
                  </h2>
                </v-col>
              </v-row>
            </v-alert>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
        class="mt-3 mb-12"
      >
        <v-col cols="12" md="6" sm="8">
          <!-- <v-divider></v-divider> -->
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <span class="text-h6 primary--text font-weight-bold">결제수단</span>
          <v-progress-linear
            :indeterminate="isProgress"
            rounded
            color="primary"
            :height="isProgress ? 6 : 4"
            :value="progressValue"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <!-- 결제수단선택 -->
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-card
            tile
            flat
            class="pa-3"
          >
            <v-radio-group
              v-model="rgPayMethod"
              row
              @change="changePayMethod"
            >
              <v-radio
                label="무통장입금"
                value="A"
                color="warning"
                class="text-subtitle-2 font-weight-bold ml-5"
              ></v-radio>
              <v-radio
                label="카드결제"
                value="B"
                color="success"
                class="text-subtitle-2 font-weight-bold ml-5"
              ></v-radio>
            </v-radio-group>
          </v-card>
        </v-col>
      </v-row>

      <!-- 계산서 발행 수단 section: 무통장입금인 경우만 활성화 -->
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-expand-transition>
            <v-card
              v-show="rgPayMethod === 'A'"
              tile
              flat
              class="pa-0 ma-0"
            >
              <v-alert
                text
                color="warning"
              >
                <h6 class="text-h6 font-weight-medium">
                  계좌번호: 하나은행 124-910006-24905
                </h6>
                <h6 class="text-h6 font-weight-medium">
                  예금주: (주)현우엘앤씨
                </h6>
                <!-- <div>이용자수와 개월수에 할인율을 적용한 금액입니다.</div> -->
                <v-divider
                  class="mt-4 mb-2 warning"
                  style="opacity: 0.22"
                ></v-divider>
                <v-radio-group
                  v-model="rgTaxType"
                  row
                  @change="changeTaxType"
                >
                  <v-radio
                    label="세금계산서"
                    value="A"
                    color="info"
                    class="text-body-2 ml-5"
                  ></v-radio>
                  <v-radio
                    label="현금영수증"
                    value="B"
                    color="brown"
                    class="text-body-2 ml-5"
                  ></v-radio>
                </v-radio-group>

                <v-row
                  align="center"
                  no-gutters
                >
                  <v-col cols="12">
                    <!-- 세금계산서 -->
                    <v-expand-transition>
                      <v-card
                        v-show="rgTaxType === 'A'"
                        tile
                        flat
                        class="pa-0 ma-0"
                      >
                        <v-alert
                          text
                          color="info"
                        >
                          <v-row no-gutters align="center" justify="center" class="py-2">
                            <v-col cols="3" class="text-left">
                              <span>상호</span>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model.trim="taxFormA.compName"
                                label="" placeholder=""
                                filled rounded
                                dense hide-details
                                class="mx-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row no-gutters align="center" justify="center" class="py-2">
                            <v-col cols="3" class="text-left">
                              <span>사업자등록번호</span>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model.trim="taxFormA.brNum"
                                label="" placeholder=""
                                filled rounded
                                dense hide-details
                                class="mx-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row no-gutters align="center" justify="center" class="py-2">
                            <v-col cols="3" class="text-left">
                              <span>대표자명</span>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model.trim="taxFormA.repName"
                                label="" placeholder=""
                                filled rounded
                                dense hide-details
                                class="mx-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row no-gutters align="center" justify="center" class="py-2">
                            <v-col cols="3" class="text-left">
                              <span>사업장주소</span>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model.trim="taxFormA.bAddress"
                                label="" placeholder=""
                                filled rounded
                                dense hide-details
                                class="mx-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row no-gutters align="center" justify="center" class="py-2">
                            <v-col cols="3" class="text-left">
                              <span>업태</span>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model.trim="taxFormA.bType"
                                label="" placeholder=""
                                filled rounded
                                dense hide-details
                                class="mx-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row no-gutters align="center" justify="center" class="py-2">
                            <v-col cols="3" class="text-left">
                              <span>종목</span>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model.trim="taxFormA.bItem"
                                label="" placeholder=""
                                filled rounded
                                dense hide-details
                                class="mx-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row no-gutters align="center" justify="center" class="py-2">
                            <v-col cols="3" class="text-left">
                              <span>세금계산서용이메일</span>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model.trim="taxFormA.email"
                                label="" placeholder=""
                                filled rounded
                                dense hide-details
                                class="mx-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row no-gutters align="center" justify="center" class="py-2">
                            <v-col cols="3" class="text-left">
                              <span>담당자</span>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model.trim="taxFormA.scaInfo"
                                label="" placeholder=""
                                filled rounded
                                dense hide-details
                                class="mx-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-alert>
                      </v-card>
                    </v-expand-transition>
                    <!--/ 세금계산서 -->
                    <!-- 현금영수증 -->
                    <v-expand-transition>
                      <v-card
                        v-show="rgTaxType === 'B'"
                        tile
                        flat
                        class="pa-0 ma-0"
                      >
                        <v-alert
                          text
                          color="brown"
                        >
                          <v-row no-gutters align="center" justify="center" class="py-2">
                            <v-col cols="3" class="text-left">
                              <span>핸드폰 번호</span>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model="taxFormB.hp"
                                label="" placeholder=""
                                filled rounded
                                dense hide-details
                                class="mx-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row no-gutters align="center" justify="center" class="py-2">
                            <v-col cols="3" class="text-left">
                              <span>담당자</span>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model="taxFormB.scbInfo"
                                label="" placeholder=""
                                filled rounded
                                dense hide-details
                                class="mx-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-alert>
                      </v-card>
                    </v-expand-transition>
                    <!--/ 현금영수증 -->
                  </v-col>
                </v-row>

              </v-alert>
            </v-card>
          </v-expand-transition>
        </v-col>
      </v-row>

      <!-- 카드결제 섹션 -->
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-expand-transition>
            <v-card
              v-show="rgPayMethod === 'B'"
              tile
              flat
              class="pa-0 ma-0"
            >
              <v-alert
                text
                color="success"
              >
                <h6 class="text-h6 font-weight-medium">
                  카드결제
                </h6>
                <v-divider
                  class="mt-4 mb-2 success"
                  style="opacity: 0.22"
                ></v-divider>

                <v-row
                  align="center"
                  no-gutters
                >
                  <v-col cols="12">
                    <v-expand-x-transition>
                      <v-card
                        tile
                        flat
                        class="pa-0 ma-0"
                      >
                        <v-alert
                          text
                          color="pink"
                        >
                          결제요청 버튼을 눌러주세요
                        </v-alert>
                      </v-card>
                    </v-expand-x-transition>
                  </v-col>
                </v-row>
              </v-alert>
            </v-card>
          </v-expand-transition>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
        class="mt-3 mb-12"
      >
        <v-col cols="12" md="6" sm="8">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
        class="mb-12"
      >
        <v-col cols="12" md="8">
          <v-row
            align="center"
            justify="center"
            class="py-4"
          >
            <v-btn
              x-large
              dark
              depressed
              color="primary"
              class="mr-2 px-12"
              @click="confirmClicked"
            >
              <span class="hidden-xs-only text-h5 mx-16 px-16">
                결제요청
              </span>
              <span class="d-flex d-sm-none text-h5 mx-8 px-8">
                결제요청
              </span>
            </v-btn>
            <v-btn
              x-large
              dark
              depressed
              color="grey darken-1"
              @click="btnCancelClick"
            >
              <span class="text-h5">취소</span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-card
            tile flat class="pa-3"
            height="100"
          >
            &nbsp;
          </v-card>
        </v-col>
      </v-row>

      <!-- 구분: 표준웹 방식 카드결제에서 사용하는 order_info form -->
      <form name="order_info" method="post">
        <!-- 주문정보 세팅 -->
        <input type="hidden" name="ordr_idxx" value="">
        <input type="hidden" name="good_name" value="">
        <input type="hidden" name="good_mny" value="">
        <!-- 구매자 정보 -->
        <input type="hidden" name="buyr_name" value="">
        <input type="hidden" name="buyr_tel1" value="">
        <input type="hidden" name="buyr_tel2" value="">
        <input type="hidden" name="buyr_mail" value="">
        <!-- 상점정보 -->
        <input type="hidden" name="pay_method" value=""><!-- 결제방법 -->
        <!--input type="hidden" name="site_cd" value="" --><!--사이트코드-->
        <input type="hidden" name="site_name" value=""><!--사이트명-->
      </form>

      <!-- 구분: 모바일 방식 카드결제에서 사용하는 m_order_info form -->
      <form name="m_order_info" method="post">
        <!-- 주문정보 세팅 -->
        <input type="hidden" name="ordr_idxx" value="">
        <input type="hidden" name="good_name" value="">
        <input type="hidden" name="good_mny" value="">
        <!-- 구매자 정보 -->
        <input type="hidden" name="buyr_name" value="">
        <input type="hidden" name="buyr_tel1" value="">
        <input type="hidden" name="buyr_tel2" value="">
        <input type="hidden" name="buyr_mail" value="">
        <!-- 상점정보 -->
        <input type="hidden" name="site_cd" value=""><!--사이트코드-->
        <input type="hidden" name="shop_name" value=""><!-- 사이트 이름 -->
        <!-- 인증시 필요한 파라미터(변경불가)-->
        <input type="hidden" name="pay_method" value="">
        <input type="hidden" name="ActionResult" value="">
        <input type="hidden" name="van_code" value="">
        <!-- 가상계좌 설정 -->
        <input type="hidden" name="ipgm_date" value="">
        <!-- 리턴 URL (kcp와 통신후 결제를 요청할 수 있는 암호화 데이터를 전송 받을 가맹점의 주문페이지 URL) -->
        <input type="hidden" name="Ret_URL" value="">
        <!-- 추가 파라미터 ( 가맹점에서 별도의 값전달시 param_opt 를 사용하여 값 전달 ) -->
        <input type="hidden" name="param_opt_1" value="">
        <input type="hidden" name="param_opt_2" value="">
        <input type="hidden" name="param_opt_3" value="">
        <!-- 거래등록 응답값 -->
        <input type="hidden" name="approval_key" id="approval" value=""/>
        <input type="hidden" name="traceNo" value="">
        <input type="hidden" name="PayUrl" value="">
        <!-- 미리 지정된 값들 -->
        <!-- 인증창 호출 시 한글깨질 경우 encoding 처리 추가 (**인코딩 네임은 대문자) -->
        <input type="hidden" name="encoding_trans" value="UTF-8">
        <input type="hidden" name="escw_used" value="N"><!-- 에스크로 -->
        <input type="hidden" name="req_tx" value="pay"><!-- 요청 구분 -->
        <input type="hidden" name="currency" value="410"><!-- 통화 코드 -->
        <!-- <input type="hidden" name="quotaopt" value="12">!-- 최대 할부개월수 -->
        <input type="hidden" name="tablet_size" value="1.0"><!-- 화면 크기조정 -->
        <!-- 제공기간: 일정시간동안만 제공하는 이벤트 상품의 기간을 지정할 때 -->
        <!-- <input type=”hidden” name="good_expr" value="1:2022050120220531"> -->
        <input type=”hidden” name="good_expr" value="0">
      </form>

    </v-card>
  </v-dialog>
</template>

<script>
import sleep from '@/lib/sleep'

// 구분: filters
import numberFormat from '@/filters/numberFormat'

// ! 카드 팝업을 열고 닫는 객체를 선언한다. - 별도 팝업에서 접근하기 위해 전역인 window 영역에 만든다.
window.cardPayWinPopRef = null
window.cardPayWinPopFunc58745125 = null

// ! 카드팝업에서 실행하는 부모창의 함수.. 역시 전역범위에서 접근하기 위해 window 변수로 지정한다.
window.cardPayWindowRefunc = function (returnMsg) {
  // 팝업 닫기
  window.cardPayWinPopRef.close()
  window.cardPayWinPopRef = null
  //
  window.cardPayWinPopFunc58745125(returnMsg)
}

export default {
  name: 'Payment',

  props: {
    source: String
  },

  components: {
  },

  data: () => ({
    dialog: false,
    isProgress: true,
    progressValue: 100,
    mustLogOut: true, // * 확인이나 취소 클릭시 반드시 로그아웃해야하는지 여부
    // laworkInfo: null,
    // 구분: 가격테이블 - VAT 포함안함
    priceTable: [
      { code: 'W5', name: '5인 이하', qty: 5, caption: '', price: 70000, disable: false },
      { code: 'W10', name: '10인 이하', qty: 10, caption: '', price: 120000, disable: false },
      { code: 'W15', name: '15인 이하', qty: 15, caption: '', price: 160000, disable: false },
      { code: 'W20', name: '20인 이하', qty: 20, caption: '', price: 190000, disable: false },
      { code: 'W0', name: '맞춤형', qty: 0, caption: '원하는 상품이 없는 경우 고객센터(02-521-7505)로 연락주십시오.', price: 0, disable: true }
    ],
    // 구분: 기간 테이블
    termTable: [
      { code: 'm1', term: 1, dc: 0, disable: false },
      { code: 'm3', term: 3, dc: 0, disable: false },
      { code: 'm6', term: 6, dc: 5, disable: false },
      { code: 'm12', term: 12, dc: 10, disable: false }
    ],
    // 구분: 상품선택의 초기값(라디오 버튼)
    rgCount: 'W5', // 이용자수 & 가격선택
    rgTerm: 'm1', // 기간 선택
    rgPayMethod: 'A', // 결제수단 선택
    rgTaxType: 'A', // 계산서 발행수단 선택
    // 구분: 뷰할 선택 가격과 총합계
    rgCountStr: '',
    rgTermStr: '',
    totalPayStr: '0',
    // 구분: 선택한 상품 가격과 기간을 담은 객체
    sgPrice: null,
    sgTerm: null,
    // 구분: 완료된 상품과 가격, 기간 객체
    selGood: {
      code: '', // 코드
      name: '', // 상품명
      qty: 0, // 수량
      term: 0, // 상품기간
      price: 0, // 상품가격
      ori_price: 0, // 결제원가격(vat 제외가)
      pay_price: 0, // 실결제가격(vat 적용가)
      isDc: 0, // 할인적용여부
      dc_price: 0, // 할인해준 가격
      dc_rate: '' // 할인율
    },
    // 구분: 기존 lawork 정보
    oLawork: null,
    // 구분: 폼 - lawork 테이블에서 필요한 것만 사용
    form: {
      id: 0,
      dbcode: '',
      officeName: '',
      email: '', // 결제자 메일
      name: '', // 결제자명
      masterEmail: '', // 관리자메일
      masterName: '', // 관리자명
      addr1: '',
      addr2: '',
      post: '',
      tel: '',
      fax: '',
      good_code: '',
      good_name: '',
      good_price: 0,
      good_qty: 0,
      good_term: 0,
      ori_price: 0,
      pay_price: 0,
      isDc: 0,
      dc_price: 0,
      dc_rate: '',
      pay_msg: '',
      tax_ok: 0, // 무통장인 경우 자동으로 0 (미발행)으로 세팅
      tax_type: '', // 계산서 발행 수단 - A(세금계산서)/B(현금영수증)
      tax_info: '', // 세금계산서/현금영수증 정보 JSON
      status: 'R', // 결제상태 - 결제대기
      method: 'A', // 결제수단 - 무통장 'A' / 카드결제 'B'
      expiredAt: '' // 서비스 만료일
    },
    // 구분: 세금계산서 폼
    taxFormA: {
      compName: '', // 상호(company-name)
      brNum: '', // 사업자등록번호(business registration number)
      repName: '', // 대표자명(name of representative)
      bAddress: '', // 회사주소
      bType: '', // 업태(business type)
      bItem: '', // 종목(business item)
      email: '', // 세금계산서 이메일
      scaInfo: '' // 담당자(Staff in Charge Information)
    },
    // 구분: 현금영수증 폼
    taxFormB: {
      hp: '', // 핸드폰 번호
      scbInfo: '' // 담당자(Staff in Charge Information)
    },
    // 구분: // post 로 넘길 kcp 등록 후 넘어온 정보
    kcpInfo: null,
    // 구분: // post 로 넘길 구매자 정보
    buyrInfo: null
  }),

  created () {
    this.dialog = true
  },

  mounted () {
    // 중요: 이 페이지로 왔다는건 이용기간이 만료된것임!
    // 중요한건 어쨓거나 로그인 정보(이메일, 디비코드 같은 필수정보)가 있어야 나머지 작업이 가능하다.

    // console.log('navigator.userAgent: ', navigator.userAgent) // view user-agent
    // 모바일인지 여부
    // console.log(`isMobile: ${this.$vuetify.breakpoint.mobile}`)

    // ! 로그인 체크 -- [2022.6.21] this.$store.state.ui 가 없어서 오류가 나네?
    // this.loginCheck()

    // 중요: 로그인으로 넘어온 토큰정보가 있으면 풀어서 사용하고
    if (this.$route.query && this.$route.query.token) {
      // console.log(this.$route.query.token)
      this.loginWithToken(this.$route.query.token).then(() => {
        // console.log('LOG: 로그인으로 받은 토큰을 풀었다')
        // console.log(this.$store.state.ui.dbcode)
        // * lawork 정보패칭
        this.getLaworkInfo()
      })
    } else {
      // 아니면 기존 토큰이 있는지 봐서 ..
      if (this.$store.state && this.$store.state.ui) {
        // console.log('LOG: 기존 있던 토큰을 풀었다')
        // console.log(this.$store.state.ui.dbcode)
        // * lawork 정보패칭
        this.getLaworkInfo()
      }
    }

    sleep(1000).then(() => { this.isProgress = false })

    // // 일반회원의 첫 로그인시 토큰을 물고온다. 아래에서 처리
    // if (this.$route.query && this.$route.query.token) { // this.$route.query 는 항시 있으니 참이구만
    //   this.loginWithToken(this.$route.query.token)
    // } else if (!this.$store.state.ui.dbcode) {
    //   // 첫 로그인이 아니라면 여기로 와선 안된다. - 이걸 따질 방법이 마땅치 않아 dbcode로 대치함
    //   this.btnCancelClick('/')
    // }
  },

  methods: {
    numberFormat,
    dummy () {
      console.log('dummy test ~')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (const key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 토큰을 백엔드에서 넘겨받아 로그인 처리하기
    async loginWithToken (token) {
      try {
        if (token) {
          // 로그인 처리 : 토큰 저장 + 토큰을 푼 유저정보를 저장
          await this.$store.dispatch('LOGIN', { token })
        } else {
          throw new Error('Token Not Reponsed : [401]')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 결제페이지는 로그인 이후에나 접근이 가능하다.
    async loginCheck () {
      try {
        if (!this.$store.state.ui) {
          const popE = await this.findParentRefs(this.$parent, 'confirmDialog')
          if (!popE) throw new Error('팝업창을 열 수 없습니다.')
          // 찾았으면 팝업을 연다
          const msg = '[오류]로그인이 필요한 페이지 입니다!'
          await popE.open('오류', msg, { color: 'error', width: 400 })
          // 위 코드는 변수로 받아 true/false를 논할 수 있지만. 여기선 무조건 아래 코드를 실행해야한다.
          await sleep(300).then(() => { this.$router.push(`/`) })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: lawork 정보 패칭해서 form 객체에 넣어주기
    async getLaworkInfo () {
      try {
        if (this.$store.state && this.$store.state.ui) {
          // ?? 백앤드에서 로워크정보를 패칭해서 this.form 객체에 채워넣기
          const { data } = await this.$axios.get(`auth/getLaworkInfoByDbcode/${this.$store.state.ui.dbcode}`)
          if (!data.success) throw new Error('오류가 발생하였습니다')
          // console.log(`data.realUserCnt: ${data.realUserCnt}`)
          //
          this.oLawork = data.laworkInfo
          //
          this.form.id = data.laworkInfo.id // * 보다시피 로워크의 아이디다
          this.form.dbcode = data.laworkInfo.dbcode
          this.form.officeName = data.laworkInfo.wname
          this.form.email = this.$store.state.ui.email // 결제자이메일
          this.form.name = this.$store.state.ui.name // 결제자명
          this.form.masterEmail = data.laworkInfo.master // 관리자 메일
          this.form.masterName = data.laworkInfo.mname // 관리자 명
          this.form.addr1 = data.laworkInfo.addr1
          this.form.addr2 = data.laworkInfo.addr2
          this.form.post = data.laworkInfo.post
          this.form.tel = data.laworkInfo.tel
          this.form.fax = data.laworkInfo.fax
          this.form.good_code = data.laworkInfo.good_code
          this.form.good_name = data.laworkInfo.good_name
          this.form.good_price = data.laworkInfo.good_price
          this.form.good_qty = data.laworkInfo.good_qty
          this.form.good_term = data.laworkInfo.good_term
          this.form.ori_price = data.laworkInfo.ori_price
          this.form.pay_price = data.laworkInfo.pay_price
          this.form.dc_rate = data.laworkInfo.dc_rate
          this.form.pay_msg = data.laworkInfo.pay_msg
          this.form.tax_ok = 0 // * tax_ok 는 lawork 에 없으므로 0
          this.form.tax_type = data.laworkInfo.tax_type
          this.form.tax_info = data.laworkInfo.tax_info
          // [2022.5.10] 서비스 만료일 넘기기 - 카드결제시에만 사용
          this.form.expiredAt = data.laworkInfo.expiredAt

          // * 결제정보 - 결제상태와 결제수단은 이전 결제의 정보가 lawork에 저장되어있지 않으니 초기값을 준다.
          this.form.status = 'R' // 결제상태 - 기본값으로 결제대기를 준다.
          this.form.method = 'A' // 결제수단 - 기본값으로 무통장입금을 준다.
          // console.log(this.form)

          // ?? 이전에 사용했던 결제상품이 있다면 그에 맞춰서 결제상품을 선택해준다.
          if (this.form.good_code === 'E5') {
            // * 기존에 이벤트였다. 그러면 기본값으로 5인이하 1개월을 준다
            this.rgCount = 'W5'
            this.rgTerm = 'm1'

            // * 선택된 상품으로 가격세팅(계산)
            this.calcGoodPrice()
            //
          } else if (this.form.good_code === 'W0') {
            // * 기존에 맞춤형이었다
            this.rgCount = 'W0'
            this.rgTerm = ''

            // * 상품선택: 로워크 관리자가 맞춤형으로 세팅했다면 맞춤형만 활성화 되어야 한다.
            for (const item of this.priceTable) {
              if (item.code === 'W0') {
                item.disable = false // 맞춤형만 활성화
                item.caption = this.form.pay_msg
              } else {
                item.disable = true
              }
            }

            // * 개월선택: 맞춤형인 경우 전부 선택불가
            for (const item of this.termTable) {
              item.disable = true
            }

            // ! 맞춤형의 경우 계산하지 않고 관리자가 세팅해준 가격을 반영해야함
            // * 결제상품가격 계산을 위해 this.selGood 객체에 대입
            this.selGood.code = this.form.good_code
            this.selGood.name = this.form.good_name
            this.selGood.qty = this.form.good_qty
            this.selGood.term = this.form.good_term
            this.selGood.price = this.form.good_price

            // * 결제금액 > 맞춤형이므로 저장된 값을 그대로 쓰면 됨
            this.selGood.pay_price = this.form.pay_price

            // * 상품원가 > 맞춤형이므로 저장된 값을 그대로 쓰면 됨
            this.selGood.ori_price = this.form.ori_price

            // * 할인율 적용
            this.selGood.isDc = 0
            this.selGood.dc_rate = this.form.dc_rate
            this.selGood.dc_price = 0
            // 할인율과 원가격이 모두 있으면 할인가를 적용한다.
            if (this.selGood.dc_rate && this.selGood.ori_price > 0) {
              this.selGood.isDc = 1
              this.selGood.dc_price = this.selGood.ori_price - this.selGood.pay_price
            }

            // * 선택가격과 총 합계금액 보여주기
            this.rgCountStr = this.selGood.name
            this.rgTermStr = this.selGood.term
            this.totalPayStr = this.selGood.pay_price
            //
          } else {
            // [2022.4.21 폐지] 기존에 이벤트도 맞춤형도 아니었다면 laworks 에 저장된 현재 결제정보를 그대로 매칭한다.
            // this.rgCount = this.form.good_code // 기존 이용자수 매칭('W5')

            // [2022.4.21 폐지]
            // 기존 기간 매칭: 이건 약간 복잡. this.form.good_term 이 정수값으로 넘어옴.
            // 하지만 this.rgTerm 는 'm12' 처럼 문자열로 들어가야한다.
            // 때문에 아래처럼 this.termTable 배열에서 정수인 term 과 매칭되는 code 값을 찾아 넣어줘야 한다.
            // this.rgTerm = this.termTable.filter(item => item.term === this.form.good_term)[0].code

            // * [2022.4.21] 기존 결제정보륿 반영하지 않고 그냥 기본값(W5)으로 세팅한다. 기본값을 기준으로 결제가격을 계산한다.
            this.rgCount = 'W5'
            this.rgTerm = 'm1'

            // ! 하위선택 불가
            // ! [2022.4.20] 실제유저수(this.realUserCnt)를 적용함. 실제유저수보다 큰경우 선택불가
            // ! 와 열받아 this.priceTable.forEach() 를 절대 쓰지말자.. 에러가 희한하게 난다.
            for (const item of this.priceTable) {
              if (item.code === 'W0') {
                // * 맞춤형 선택불가
                item.disable = true
              } else if (item.qty < this.realUserCnt) { // * 실제유저수를 적용함
                item.disable = true
              }
            }

            // [2022.4.21 폐지] 선택된 상품으로 가격세팅(계산)
            // this.calcGoodPrice()
            // * 선택된 기본값 W5(5인 이하)가 선택가능해야 계산한다. 라디오버튼이 비활성화되서 선택불가면 계산하지 않는다.
            if (!this.priceTable[0].disable) {
              this.calcGoodPrice()
            }
          }

          // ?? 이전에 사용했던 결제수단은 저장된바가 없다.(저장하려면 lawork에 최근 결제수단을 저장하는 필드를 만들어야 한다.)
          // ?? 다만 이전에 언젠가 계산서타입과 정보는 저장되어있을 수 있다.
          // * 결제수단 세팅 > 이 함수의 위에서 하드코딩으로 A(무통장입금)로 세팅했다.
          this.rgPayMethod = this.form.method

          // * 결제수단이 무통장인 경우 계산서타입등을 세팅한다.
          if (this.rgPayMethod === 'A') {
            // * 계산서 타입 세팅(A:세금계산서, B:현금영수증) > 기존값이 없으면 'A'
            this.rgTaxType = this.form.tax_type || 'A'

            // * 이전의 계산서 정보가 있다면 세팅한다.
            this.setPayMethod()
          }

          // ![2022.4.13] 확인/취소 버튼을 클릭했을때 기간이 남은 경우 이전페이지로 이동시키고 기간이 없는 경우 로그아웃 시키는 로직이다.
          if (this.$moment(this.oLawork.expiredAt).startOf('day').diff(this.$moment(), 'days') < 0) {
            // * 만료일이 지났다 -> mustLogOut = true 로 해서 반드시 로그아웃해야 한다.
            this.mustLogOut = true
          } else {
            // * 만료일이 남았다(오늘 + 미래) -> mustLogOut = false 로 해서 뒤로 백하게 한다.
            this.mustLogOut = false
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소버튼 클릭 이벤트 핸들러
    btnCancelClick (to = '') {
      this.dialog = false
      if (!this.mustLogOut) {
        // * 만료일이 남은 경우 -> 이전 페이지로 백시킨다!
        this.$router.go(-1) // window.history.back() 과 동일한 효과
        //
      } else {
        // * 만료일이 지난 경우 -> 로그아웃 시킨다.
        this.$store.dispatch('LOGOUT').then(() => this.$router.push('/')) // * [2022.3.15]로그아웃처리
        //
      }
    },
    // 구분: 이전의 계산서 정보가 있다면 세팅하는 함수
    async setPayMethod () {
      try {
        // * 세금계산서폼과 현금영수증폼 초기화
        this.initTaxFormA()
        this.initTaxFormB()

        // * 이전 언젠가 .. 계산서타입과 계산서정보가 manage.laworks에 있었다면
        if (this.oLawork.tax_type && this.oLawork.tax_info) {
          if (this.rgTaxType === 'A' && this.oLawork.tax_type === 'A') {
            // * 세금계산서인 경우
            this.taxFormA = Object.assign(this.taxFormA, JSON.parse(this.oLawork.tax_info))
            //
          } else if (this.rgTaxType === 'B' && this.oLawork.tax_type === 'B') {
            // * 현금영수증인 경우
            this.taxFormB = Object.assign(this.taxFormB, JSON.parse(this.oLawork.tax_info))
            //
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 세금계산서폼 초기화
    async initTaxFormA () {
      this.taxFormA.compName = ''
      this.taxFormA.brNum = ''
      this.taxFormA.repName = ''
      this.taxFormA.bAddress = ''
      this.taxFormA.bType = ''
      this.taxFormA.bItem = ''
      this.taxFormA.email = ''
      this.taxFormA.scaInfo = ''
    },
    // 구분: 현금영수증폼 초기화
    async initTaxFormB () {
      this.taxFormB.hp = ''
      this.taxFormB.scbInfo = ''
    },
    // 구분: 이용자수 라디오 버튼 변경 이벤트 핸들러
    async changeCount () {
      try {
        // 가격 계산
        this.calcGoodPrice()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 기간 라디오 버튼 변경 이벤트 핸들러
    async changeTerm () {
      try {
        // 가격 계산
        this.calcGoodPrice()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 결제수단 라디오 버튼 변경 이벤트 핸들러
    async changePayMethod () {
      try {
        // * 결제수단이 무통장인 경우 계산서타입등을 세팅한다.
        if (this.rgPayMethod === 'A') {
          // * 계산서 타입 세팅(A:세금계산서, B:현금영수증) > 기존값이 없으면 'A'
          this.rgTaxType = this.form.tax_type || 'A'

          // * 이전의 계산서 정보가 있다면 세팅한다.
          this.setPayMethod()
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 계산서 발행수단 라디오 버튼 변경 이벤트 핸들러. 세금계산서/현금영수증 처리
    async changeTaxType () {
      try {
        // * 이전의 계산서 정보가 있다면 세팅한다.
        this.setPayMethod()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: selGood 객체 초기화
    initSelGood () {
      this.selGood.code = ''
      this.selGood.name = ''
      this.selGood.qty = 0
      this.selGood.term = 0
      this.selGood.price = 0
      this.selGood.ori_price = 0
      this.selGood.pay_price = 0
      this.selGood.isDc = 0
      this.selGood.dc_price = 0
      this.selGood.dc_rate = ''
    },
    // 구분: 선택된 상품으로 가격을 계산한다
    async calcGoodPrice () {
      try {
        // selGood 객체 초기화
        this.initSelGood()

        // * 이용자수 선택값
        this.sgPrice = this.priceTable.filter(item => item.code === this.rgCount)[0]

        // * 기간은 오류를 줄이기 위해 약간 복잡한 조작이 필요하다.
        if (typeof this.rgTerm === 'string') {
          // 문자열인 경우 'm12' 같은 형태이니 숫자만 남기고 number() 로 정수로 만든다
          const rgTermNumber = Number(this.rgTerm.replace('m', ''))
          this.sgTerm = this.termTable.filter(item => item.term === rgTermNumber)[0]
        } else if (typeof this.rgTerm === 'number') {
          this.sgTerm = this.termTable.filter(item => item.term === this.rgTerm)[0]
        }

        // 결제상품가격 계산을 위해 this.selGood 객체에 대입
        this.selGood.code = this.sgPrice.code
        this.selGood.name = this.sgPrice.name
        this.selGood.qty = this.sgPrice.qty
        this.selGood.term = this.sgTerm.term
        this.selGood.price = this.sgPrice.price

        // * 상품원가 = 상품가격 x 기간
        this.selGood.ori_price = this.selGood.price * this.selGood.term

        // * 할인율 적용
        if (this.sgTerm.dc > 0) {
          this.selGood.isDc = 1
          this.selGood.dc_rate = `${this.sgTerm.dc}`

          // 할인적용가 = 상품원가 x 할인율 적용
          const dcPrice = this.selGood.ori_price * ((100 - this.sgTerm.dc) / 100)
          // 할인해준가격 = 상품원가 - 할인적용가
          this.selGood.dc_price = this.selGood.ori_price - dcPrice

          // vat 적용한 실결제가 = 할인적용가 x 1.1
          this.selGood.pay_price = dcPrice * 1.1
        } else {
          this.selGood.isDc = 0
          this.selGood.dc_rate = ''
          this.selGood.dc_price = 0

          // vat 적용한 실결제가 = 상품원가 x 1.1
          this.selGood.pay_price = this.selGood.ori_price * 1.1
        }

        // 최종결제가 - 100원단위 절사
        this.selGood.pay_price = Math.floor(this.selGood.pay_price / 1000) * 1000

        // * 선택가격과 총 합계금액 보여주기
        this.rgCountStr = this.selGood.name
        this.rgTermStr = this.selGood.term
        this.totalPayStr = this.selGood.pay_price
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 결제확인 버튼 Click 이벤트 핸들러
    async confirmClicked () {
      try {
        if (!this.form.id) throw new Error('[id not found] 잘못된 요청입니다.')
        if (!this.form.dbcode) throw new Error('[dbcode not found] 잘못된 요청입니다.')
        if (!this.form.email) throw new Error('[email not found] 잘못된 요청입니다.')
        if (!this.form.masterEmail) throw new Error('[masterEmail not found] 잘못된 요청입니다.')
        // 결제금액이 계산되지 않아서 0인 경우 에러
        if (!this.selGood.pay_price) throw new Error('[price not found] 결제금액이 없습니다.')

        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = this.rgPayMethod === 'A' ? '무통장결제를 등록하시겠습니까?' : '카드결제를 진행하시겠습니까?'
        const titMsg = this.rgPayMethod === 'A' ? '무통장결제' : '카드결제'
        if (await pop.open(titMsg, msg, { color: 'success', width: 350 })) {
          // * 결제상품정보 세팅
          this.form.good_code = this.selGood.code
          this.form.good_name = this.selGood.name
          this.form.good_price = this.selGood.price
          this.form.good_qty = this.selGood.qty
          this.form.good_term = this.selGood.term
          this.form.isDc = this.selGood.isDc
          this.form.dc_price = this.selGood.dc_price
          this.form.dc_rate = this.selGood.dc_rate
          this.form.ori_price = this.selGood.ori_price
          this.form.pay_price = this.selGood.pay_price
          // max_user 는 qty 로 세팅한다.
          // this.form.max_user = this.selGood.qty
          // * 결제수단 세팅: 무통장 / 카드
          this.form.method = this.rgPayMethod
          // * 세금계산서 정보 초기화
          this.form.tax_info = null

          // ? 1) 무통장입금인 경우 계산서정보(세금계산서/현금영수증) 처리
          if (this.form.method === 'A') {
            this.form.tax_ok = 0 // 세금계산서 발행여부는 미발행으로 세팅
            this.form.tax_type = this.rgTaxType // 계산서 발행수단 세팅

            // * taxFormA 나 taxFormB에 값이 있는 경우 문자열로 만들어서 this.form.tax_info 에 넣는다.
            if (this.rgTaxType === 'A') { // 세금계산서
              if (Object.values(this.taxFormA).filter(item => item !== '').length > 0) { // 폼값이 하나라도 채워졌으면..
                this.form.tax_info = JSON.stringify(this.taxFormA)
              }
            } else { // 현금영수증
              if (Object.values(this.taxFormB).filter(item => item !== '').length > 0) {
                this.form.tax_info = JSON.stringify(this.taxFormB)
              }
            }

            // * 폼값 전송
            const { data } = await this.$axios.post('auth/setOrderDeposit', this.form)
            // if (!data.success) throw new Error(`오류가 발생하였습니다`)
            // if (!data.retStatus) throw new Error(`[retStatus: failed]입력오류가 발생하였습니다`)
            if (!data.retStatus) {
              // # DB 입력오류가 발생했다.
              const popE = await this.findParentRefs(this.$parent, 'confirmDialog')
              if (!popE) throw new Error('팝업창을 열 수 없습니다.')
              // 찾았으면 팝업을 연다
              const msg = '[retStatus: failed]<br>입력오류가 발생하였습니다.<br>다시 등록하시기 바랍니다'
              if (await popE.open('오류', msg, { color: 'error', width: 400 })) {
                // await sleep(300).then(() => { this.$router.push(`/`) })
              }
            } else {
              // # 이상이 없으면 입금대기 화면으로 빼자
              this.dialog = false
              this.$router.push('/member/payRequest')
            }
          } else if (this.form.method === 'B') {
            // ? 2) 카드결제인 경우 처리

            // ! window.cardPayWinPopFunc58745125 를 초기화하고 다시 세팅한다.
            // ! 별도 팝업인 /member/cardSuccess.vue, cardFailed.vue 파일에서 부모를 호출하기 위해서 전역변수를 window 영역으로 만들었다
            window.cardPayWinPopFunc58745125 = null
            // * 함수등록: 이 함수는 로그아웃 시키고 / 로 보낸다.
            window.cardPayWinPopFunc58745125 = async (returnMsg) => {
              const popE = await this.findParentRefs(this.$parent, 'confirmDialog')
              if (!popE) throw new Error('팝업창을 열 수 없습니다.')
              // 찾았으면 팝업을 연다
              // * returnMsg 에 따라 성공/실패로 나뉜다
              if (returnMsg === 'success') {
                // 결제 성공
                const msg = '카드결제가 성공하였습니다.<br>보안을 위해 로그아웃되오니 재로그인해주시기 바랍니다.'
                if (await popE.open('카드결제 성공', msg, { color: 'success', width: 400 })) {
                  this.$store.dispatch('LOGOUT')
                    .then(() => {
                      this.$router.push('/')
                    })
                }
              } else {
                // 결제 실패
                const msg = '카드결제가 실패하였습니다.<br>다시 한 번 확인해 주시기 바랍니다.'
                if (await popE.open('카드결제 실패', msg, { color: 'error', width: 400 })) {
                  //
                }
              }
            }

            if (navigator.userAgent.indexOf('Win') !== -1) {
              // # windows os 인 경우 화면이 작으면 모바일로 띄울수 있으니 무조건 표준웹으로 띄운다.
              await this.doCardPayment()
              //
            } else {
              if (this.$vuetify.breakpoint.mobile) {
                // # 모바일인 경우
                await this.doCardPaymentMobile()
              } else {
                // # 표준웹인 경우
                await this.doCardPayment()
              }
            }
            //
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 표준웹(PC)방식 카드결제 처리
    async doCardPayment () {
      try {
        // order_infor form 세팅하기
        await this.setOrderInfoForm()

        const oForm = document.order_info

        const popWidth = 735
        const popHeight = 560

        // 브라우저의 모니터 상 x, y 좌표
        const winX = window.screenX || window.screenLeft || 0
        const winY = window.screenY || window.screenTop || 0
        // 부모창의 너비, 길이
        const curWidth = document.body.clientWidth
        const curHeight = document.body.clientHeight
        // 부모창의 중앙에 위치 시키기
        const popX = winX + (curWidth / 2) - (popWidth / 2)
        const popY = winY + (curHeight / 2) - (popHeight / 2)
        const popOption = `status=no, width=${popWidth}, height=${popHeight}, left=${popX}, top=${popY}, screenX=${popX}, screenY=${popY}`
        window.cardPayWinPopRef = window.open('', 'popup-pay', popOption)
        oForm.target = 'popup-pay'
        oForm.submit()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 표준웹방식 카드결제 주문 폼 세팅
    async setOrderInfoForm () {
      try {
        const oForm = document.order_info

        // * 20자리의 주문/결제 코드 생성
        const orderCode = '' + this.$moment().format('YYYYMMDDHHmmss') + Math.floor(Math.random() * 1000000)

        // ! 상품명은 표준웹방식에서는 중요하다. 상품명-상품코드-기간-수량을 전달하는 중요한 정보로서 기능한다.
        oForm.good_name.value = `Lawork-${this.form.good_name}-${this.form.good_code}-${this.form.good_term}-${this.form.good_qty}`
        oForm.ordr_idxx.value = orderCode
        oForm.good_mny.value = `${this.form.pay_price}` // * 상품가격: 문자열로 보내야 한다.
        oForm.buyr_name.value = this.form.name
        oForm.buyr_tel1.value = this.form.tel
        oForm.buyr_tel2.value = this.form.fax
        oForm.buyr_mail.value = this.form.email
        oForm.pay_method.value = '100000000000' // # 신용카드로 픽스
        // oForm.site_cd.value = 'T0000' // 백앤드에서 처리 테스트용 사이트 코드로 픽스 > 백앤드에서 실서비스인 경우 변경함
        oForm.site_name.value = 'lawork'

        // * 백앤드 루트 경로를 form.action 에 대입
        const apiRootPath = process.env.NODE_ENV !== 'production' ? 'http://localhost:3000/v1/' : '/v1/'
        oForm.action = `${apiRootPath}auth/kcpRelay` // 중계경로
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 모바일 방식 카드결제 처리
    async doCardPaymentMobile () {
      try {
        // 여기서 부터
        this.kcpInfo = null
        this.buyrInfo = null

        // * 20자리의 주문/결제 코드 생성
        const orderCode = '' + this.$moment().format('YYYYMMDDHHmmss') + Math.floor(Math.random() * 1000000)

        // #1 모바일거래 등록 api 로 데이터 전송하기
        const kcpApiRegParams = {
          ordr_idxx: orderCode,
          good_name: `Lawork-${this.form.good_code}-${this.form.good_term}`,
          good_mny: `${this.form.pay_price}`, // * 상품가격: 문자열로 보내야 한다.
          ActionResult: 'card',
          pay_method: 'CARD',
          van_code: '',
          Ret_URL: 'https://www.lawork.co.kr/v1/auth/kcpApiPay', // ! 결제 후 리턴url(절대경로 - 기본값으로 실제 서비스 경로를 준다.)
          user_agent: '',
          // site_cd: '', // 사이트코드를 넘기지 않는다.
          // 구매자 정보 추가
          buyr_name: this.form.name,
          buyr_tel1: this.form.tel,
          buyr_tel2: this.form.fax,
          buyr_mail: this.form.email
        }
        const { data } = await this.$axios.post('auth/kcpApiTradeReg', kcpApiRegParams)
        if (!data.success) throw new Error('오류가 발생하였습니다')
        if (!data.retStatus) { // * 리턴된 상태값이 오류인 경우
          // ? 오류가 발생했다.
          const popE = await this.findParentRefs(this.$parent, 'confirmDialog')
          if (!popE) throw new Error('팝업창을 열 수 없습니다.')
          // 찾았으면 팝업을 연다
          let msg = `[retStatus: ${data.retCode}] 카드결제 등록에 실패하였습니다.`
          if (data.retCode === '3001') {
            msg = '[res code: 3001] 사용자가 취소하였습니다.'
          }
          if (await popE.open('오류', msg, { color: 'error', width: 400 })) {
            //
          }
        } else {
          // ? 이상이 없으면 아래 변수에 매칭
          this.kcpInfo = data.kcpInfo
          this.buyrInfo = data.buyrInfo
          //
          this.initCardOrderForm() // 카드결제용 폼을 초기화 한다.

          const oForm = document.m_order_info

          // * 결제창 띄우기
          // 인코딩 방식에 따른 변경 start
          if (oForm.encoding_trans === undefined) {
            oForm.action = this.kcpInfo.PayUrl
          } else {
            if (oForm.encoding_trans.value === 'UTF-8') {
              // ! 한글처리: utf8 filtering 된 경로로 보내야 한글이 깨지지 않는다.
              const utf8url = this.kcpInfo.PayUrl.substring(0, this.kcpInfo.PayUrl.lastIndexOf('/')) + '/jsp/encodingFilter/encodingFilter.jsp'
              // console.log(utf8url)
              // oForm.action = this.kcpInfo.PayUrl
              oForm.action = utf8url
            } else {
              oForm.action = this.kcpInfo.PayUrl
            }
          }
          // 인코딩 방식에 따른 변경 end
          // console.log(oForm)

          // console.log(oForm)
          // #1 이렇게 하면 탭을 새로 열고 이동시킨다.
          // oForm.target = 'cardOrderFrame'
          // oForm.submit()

          // #2 작은 팝업을 띄우는 방식
          // * KCP 카드결제 팝업을 부모창의 중앙에 위치시키는 방법
          const popWidth = this.$vuetify.breakpoint.mobile ? 390 : 590
          const popHeight = this.$vuetify.breakpoint.mobile ? 450 : 530
          // 브라우저의 모니터 상 x, y 좌표
          const winX = window.screenX || window.screenLeft || 0
          const winY = window.screenY || window.screenTop || 0
          // 부모창의 너비, 길이
          const curWidth = document.body.clientWidth
          const curHeight = document.body.clientHeight
          // 부모창의 중앙에 위치 시키기
          const popX = winX + (curWidth / 2) - (popWidth / 2)
          const popY = winY + (curHeight / 2) - (popHeight / 2)
          // 사용안함: 모니터 중앙에 위치 시키기 > 듀얼모니터에서는 예상치 않은곳에 떠버린다.
          // const popX = (window.screen.width / 2) - (popWidth / 2)
          // const popY = (window.screen.height / 2) - (popHeight / 2)

          const popOption = `status=no, width=${popWidth}, height=${popHeight}, left=${popX}, top=${popY}, screenX=${popX}, screenY=${popY}`
          window.cardPayWinPopRef = window.open('', 'popup-pay', popOption)
          oForm.target = 'popup-pay'
          oForm.submit()
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 카드결제용 폼을 초기화 한다.
    initCardOrderForm () {
      const oForm = document.m_order_info
      //
      // oForm.ordr_idxx.value = ''
      // oForm.good_name.value = ''
      // oForm.good_mny.value = ''

      // oForm.buyr_name.value = ''
      // oForm.buyr_tel1.value = ''
      // oForm.buyr_tel2.value = ''
      // oForm.buyr_mail.value = ''

      // oForm.site_cd.value = ''
      // oForm.shop_name.value = ''

      // oForm.pay_method.value = ''
      // oForm.ActionResult.value = ''
      // oForm.van_code.value = ''
      // oForm.Ret_URL.value = ''

      // oForm.approval_key.value = ''
      // oForm.traceNo.value = ''
      // oForm.PayUrl.value = ''
      //
      // oForm.good_name.value = this.form.good_name.replace(' ', '') // 얘만 this.form.good_name 에서 가져옴.공백제거
      // * 한글이 깨지므로 영문 상품명으로 보냄
      oForm.good_name.value = `Lawork-${this.form.good_code}-${this.form.good_term}`

      oForm.ordr_idxx.value = this.kcpInfo.ordr_idxx
      oForm.good_mny.value = this.kcpInfo.good_mny

      oForm.site_cd.value = this.kcpInfo.site_cd
      oForm.pay_method.value = this.kcpInfo.pay_method
      oForm.ActionResult.value = this.kcpInfo.actionResult
      oForm.van_code.value = this.kcpInfo.van_code
      oForm.Ret_URL.value = this.kcpInfo.Ret_URL

      oForm.approval_key.value = this.kcpInfo.approvalKey
      oForm.traceNo.value = this.kcpInfo.traceNo
      oForm.PayUrl.value = this.kcpInfo.PayUrl

      // 상점명과 구매자 정보는 등록 후 넘어온 this.buyrInfo 에서 매칭하면 됨
      oForm.shop_name.value = this.buyrInfo.shop_name
      oForm.buyr_name.value = this.buyrInfo.buyr_name
      oForm.buyr_tel1.value = this.buyrInfo.buyr_tel1
      oForm.buyr_tel2.value = this.buyrInfo.buyr_tel2
      oForm.buyr_mail.value = this.buyrInfo.buyr_mail

      // * [2022.5.6] 추가 파라미터로 폼값 넘기기 -- DB 처리를 위한 정보를 넘긴다.
      oForm.param_opt_1.value = JSON.stringify(this.form)
    }
  }
}
</script>
